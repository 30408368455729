import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../Components/Button";
import {
  ButtonWrapper,
  PrintingContent,
} from "../Styles/ViewStyles/PrintingStyles";
import { getHeaders, createNewWebSocket } from "../Actions/actionCreator";
import { GenericModal } from "../Components/Modal";

const Printing = ({
  sentDataToLogs,
  client,
  ticketPrintWebSocket,
  bocaStatusWebSocket,
  orderId,
  createNewWebSocket,
  API,
  printerOnline,
}) => {
  const history = useHistory();
  const [error, setError] = useState("");

  const handleErrorClose = () => {
    history.push("/");
  };

  const handleRedirect = (isDigital = false) => {
    history.push({ pathname: "/ThankYou", state: { isDigital } });
  };

  const updateOrderTicketType = async (isDigital) => {
    const headers = getHeaders(client);
    return await axios
      .put(
        `${API}/api/kiosk/${orderId}/update-order-ticket-type`,
        {
          orderId,
          orderTicketType: isDigital ? "Digital" : "Printed",
        },
        headers
      )
      .then(() => true)
      .catch((err) => {
        sentDataToLogs("can't save ticket type: ", err?.message);
        return false;
      });
  };

  const handleDigitalUse = async () => {
    const isUpdatedTicketType = await updateOrderTicketType(true);
    if (isUpdatedTicketType) {
      handleRedirect(true);
    }
  };

  const handleTicketPrint = async () => {
    const isUpdatedTicketType = await updateOrderTicketType();
    if (!isUpdatedTicketType) {
      return;
    }

    const appKey = getHeaders(client, true);
    if (
      bocaStatusWebSocket.checkIsAvailable() &&
      ticketPrintWebSocket.checkIsAvailable()
    ) {
      ticketPrintWebSocket.printTickets(orderId, appKey);
      handleRedirect();
    } else {
      createNewWebSocket();
      setError(
        `There was a problem printing your tickets. Please see the ticket window. Your order # is ${orderId}`
      );
      sentDataToLogs("printer unavailable");
    }
  };

  return (
    <>
      <GenericModal
        label={error}
        open={!!error}
        click={handleErrorClose}
        client={client}
      />

      <PrintingContent>
        <ButtonWrapper client={client}>
          <Button
            click={handleDigitalUse}
            label="DIGITAL TICKET"
            client={client}
            long
            second={client !== "cgg"}
          />

          <Button
            click={handleTicketPrint}
            label="PRINT TICKET"
            client={client}
            long
            second={client === "cgg"}
            disabled={!printerOnline}
          />
        </ButtonWrapper>
      </PrintingContent>
    </>
  );
};

const mapStateToProps = (state) => ({
  API: state.API,
  client: state.client,
  ticketPrintWebSocket: state.ticketPrintWebSocket,
  bocaStatusWebSocket: state.bocaStatusWebSocket,
  orderId: state.orderStatus.orderId,
  printerOnline: state.printerOnline,
});

const mapDispatchToProps = {
  createNewWebSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(Printing);
